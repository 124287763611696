.online {
	height: 100%;
	overflow: scroll;
	background-color: var(--primary-500);
	border-radius: 10px;
	color: white;
	scrollbar-width: none; /*to work in moz*/
	padding: 2rem;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
}

.online::-webkit-scrollbar {
	display: none;
}

.online h2 {
	font-size: 1.1rem;
	font-weight: 500;
}

.online .user {
	position: relative;
	display: flex;
	color: white;
	align-items: center;
	gap: 1rem;
	transition: transform 0.2s linear;
}

.user:not(.online-users .user):hover {
	transform: translateX(10px);
}

.online img {
	width: 2rem;
}

.online h3 {
	font-weight: 500;
	font-size: 0.8rem;
}

.online-users {
	display: flex;
	gap: 0.5rem;
	width: 100%;
	overflow-x: auto;
	flex-shrink: 0;
	scrollbar-width: 0;
}

.online-users::-webkit-scrollbar {
	display: none;
}

.online-users > * {
	flex-shrink: 0;
}

.user-avatar {
	position: relative;
	display: inline-block; /* Adjust based on your layout */
}

.avatar-container {
	position: relative;
	display: flex; /* This will help with alignment */
	align-items: center; /* Center items vertically */
}

.verified-badge {
	display: none;
	position: absolute;
	left: 0px; /* Adjust based on your preference */
	top: 90%;
	transform: translateY(-50%);
	z-index: 2; /* Ensure badge is above the avatar */
	width: 10px; /* Adjust width as needed. */
}

.rounded-green {
	position: relative;
	display: inline-flex; /* Wrap the avatar */
	align-items: center; /* Center the avatar vertically */
	justify-content: center; /* Center the avatar horizontally */
	border-radius: 50%; /* Creates the circular shape */
	padding: 0px; /* Adjust the padding to control the size of the green circle */
	background-color: #00ff00; /* The color of the circle indicating online status */
	box-shadow: 0 0 0 2px #00ff00; /* Adjusts the size of the green outline */
}

.roundimage {
	display: block;
	width: 100%; /* Make the image fit the container */
	height: auto; /* Maintain aspect ratio */
	border-radius: 50%; /* Ensure the image is circular */
	z-index: 1; /* Ensures avatar is below the badge but above the background */
}
