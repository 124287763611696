.createpost {
	background-color: var(--primary-500);
	padding: 2rem 1rem;
	border-radius: 10px;
}

.createpost textarea {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	width: 100%;
	height: 100px;
	resize: none;
	color: white;
	font-size: 1rem;
	background-color: var(--primary-600);
	border-radius: 10px;
	padding: 1rem;
}

.createpost button,
label div {
	background-color: rgba(255, 255, 255, 0.158);
	width: 50px;
	aspect-ratio: 1;
	border-radius: 50%;
	position: relative;
}

input[type="file"] {
	display: none;
}

label div img,
.createpost button img {
	height: 20px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-45%, -50%);
}

label div {
	cursor: pointer;
}

.btns {
	margin-top: 1rem;
	display: flex;
	gap: 1rem;
	width: max-content;
	margin-left: auto;
}

.uploaded-image {
	width: 100%;
	overflow: hidden;
	position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 200px;
  gap: 5px;
}

.uploaded-image img {
	width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.uploaded-image .close-icon {
	position: absolute;
	right: 1rem;
	top: 1rem;
	background-color: var(--primary-500);
	height: 30px;
	aspect-ratio: 1;
	border-radius: 50%;
	display: grid;
	place-content: center;
	cursor: pointer;
}

.uploaded-image .preview-item {
  position: relative;
}

@media (max-width: 800px) {
	.createpost {
		padding: 1rem;
	}
}



.youtube-embed {
	/* Apply styles to the video container */
	width: 100%; /* Ensure the video takes the full width of its container */
	margin-top: 10px; /* Add some space between the video and other elements */
	display: flex; /* Center the video horizontally */
	justify-content: center; /* Center the video horizontally */
	position: relative; /* Ensure proper positioning of the video */
}

.youtube-embed iframe {
	/* Apply styles to the iframe containing the video */
	width: 100%; /* Ensure the video takes the full width of its container */
	height: auto; /* Allow the height to adjust according to the video's aspect ratio */

	@media (min-width: 768px) {
		/* For screens wider than 768px (e.g., tablets and desktops) */
		width: 560px; /* Set a fixed width for the iframe */
		height: 315px; /* Set a fixed height for the iframe (16:9 aspect ratio) */
	}

	@media (min-width: 992px) {
		/* For screens wider than 992px (e.g., larger desktops) */
		width: 800px; /* Adjust width for larger screens if necessary */
		height: 450px; /* Adjust height for larger screens if necessary */
	}

	@media (min-width: 1200px) {
		/* For screens wider than 1200px (e.g., very large desktops) */
		width: 960px; /* Adjust width for very large screens if necessary */
		height: 540px; /* Adjust height for very large screens if necessary */
	}
}


.youtube-embed {
	/* Apply styles to the video container */
	width: 100%; /* Ensure the video takes the full width of its container */
	margin-top: 10px; /* Add some space between the video and other elements */
	display: flex; /* Center the video horizontally */
	justify-content: center; /* Center the video horizontally */
	position: relative; /* Ensure proper positioning of the video */
}

.youtube-embed iframe {
	/* Apply styles to the iframe containing the video */
	width: 100%; /* Ensure the video takes the full width of its container */
	height: auto; /* Allow the height to adjust according to the video's aspect ratio */

	@media (min-width: 768px) {
		/* For screens wider than 768px (e.g., tablets and desktops) */
		width: 560px; /* Set a fixed width for the iframe */
		height: 315px; /* Set a fixed height for the iframe (16:9 aspect ratio) */
	}

	@media (min-width: 992px) {
		/* For screens wider than 992px (e.g., larger desktops) */
		width: 800px; /* Adjust width for larger screens if necessary */
		height: 450px; /* Adjust height for larger screens if necessary */
	}

	@media (min-width: 1200px) {
		/* For screens wider than 1200px (e.g., very large desktops) */
		width: 960px; /* Adjust width for very large screens if necessary */
		height: 540px; /* Adjust height for very large screens if necessary */
	}
}
