.search-results {
	position: absolute;
	width: 100%;
	left: 0;
	height: 85vh;
	overflow: auto;
	top: calc(100% + 1rem);
	background-color: var(--primary-500);
	padding: 2rem 1.5rem;
	border-radius: 10px;
	box-shadow: 0 0 30px rgba(0, 0, 0, 0.466);
	color: white;
	display: flex;
	flex-direction: column;
	gap: 1.5rem;
	scrollbar-width: none;
}

.search-results::-webkit-scrollbar {
	display: none;
}

.search-results h3 {
	font-size: 1.2rem;
	width: min-content;
	margin-bottom: 1em;
	border-bottom: 2px solid white;
}

.search-results p {
	width: 90%;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-size: 0.9rem;
	color: rgba(255, 255, 255, 0.534);
	margin-bottom: 1em;
}

.search-results p:hover {
	text-decoration: underline;
}

@media (max-width: 800px) {
	.search-results {
		position: fixed;
		height: auto;
		inset: 7rem 1rem 1rem;
		width: initial;
	}
}
