.auth {
	perspective: 1200px;
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 1rem;
}

.login,
.register {
	position: absolute;
	left: 50%;
	top: 50%;
	backface-visibility: hidden;
	transform-style: preserve-3d;
	transition: transform 0.4s linear;
	transform: translate(-50%, -50%);
}

.register {
	transform: translate(-50%, -50%) rotateY(-180deg);
}

.signup .login {
	transform: translate(-50%, -50%) rotateY(180deg);
}

.signup .register {
	transform: translate(-50%, -50%) rotateY(0deg);
}

.auth p {
	margin-inline: auto;
	line-height: 1.8em;
	width: max-content;
	margin-top: 1.5rem;
	text-align: center;
}

.auth form {
	background-color: var(--primary-500);
	color: white;
	width: 400px;
	padding: 3rem 1.5rem 1.5rem 1.5rem;
	border-radius: 10px;
}

.auth label {
	display: block;
	margin-bottom: 0.5rem;
	padding-left: 0.3rem;
	font-weight: 300;
	font-size: 0.85rem;
}

.auth input {
	background-color: var(--primary-600);
	width: 100%;
	padding: 0.75rem 1rem;
	color: white;
	border-radius: 10px;
	margin-bottom: 2rem;
}

.auth button {
	background: var(--primary-400);
	width: 100%;
	color: white;
	padding: 0.75rem;
	border-radius: 10px;
	font-size: 1rem;
}

.auth p span:hover {
	cursor: pointer;
	text-decoration: underline;
}

.PhoneInput input {
	margin-bottom: 0 !important;
}

.auth input.auth-code-input {
	width: 45px;
	height: 45px;
	padding: 0;
	font-size: 24px;
	text-align: center;
	margin-right: 12px;
	text-transform: uppercase;
	color: #494949;
	font-family: SF Pro Text, SF Pro Icons, Helvetica Neue, Helvetica, Arial,
		sans-serif;
	border: 1px solid #d6d6d6;
	border-radius: 4px;
	background: #fff;
	background-clip: padding-box;
}

.auth input.auth-code-input:focus {
	appearance: none;
	outline: 0;
	box-shadow: 0 0 0 3px rgb(131 192 253 / 50%);
}

@media (max-width: 420px) {
	.auth form {
		width: 350px;
	}
}