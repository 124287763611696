.reportpost {
    width: min(90vw, 700px);
    max-height: 70vh;
    overflow: scroll;
    border-radius: 10px;
    scrollbar-width: 0;
}

.reportpost::-webkit-scrollbar {
    display: none;
}
