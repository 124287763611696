/* Cookies.css */
.cookie-policy {
	display: flex;
	flex-direction: column;
	gap: 1rem;
	padding: 2px;
	height: auto; /* Adjusted for the content's length */
	border-radius: 10px;
	background-color: var(--background-color, #f0f0f0); /* Example background color, adjust as needed */
}

.cookie-policy h4, .cookie-policy h5 {
	margin-bottom: 16px;
}

.cookie-policy p {
	margin-bottom: 16px;
}

.cookie-policy__updated {
	margin-bottom: 24px;
}

/* Adjustments for mobile view */
@media (max-width: 800px) {
	.cookie-policy {
		padding: 1.5rem 0.75rem;
	}

	.cookie-policy h4, .cookie-policy h5 {
		margin-bottom: 12px;
	}

	.cookie-policy p {
		margin-bottom: 12px;
	}

	.cookie-policy__updated {
		margin-bottom: 18px;
	}
}

/* Last paragraph margin reset */
.cookie-policy p:last-of-type {
	margin-bottom: 0;
}